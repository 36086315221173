import "./Footer.style.css";

const Footer = (props) => {
  return (
    <footer className="footer-distributed">
      <div className="footer-right">
        <a href="https://www.bmwi.de/Navigation/DE/Home/home.html">
          <img
            src="footer-logos.png"
            id="footer-logo"
          />
        </a>
      </div>

      <div className="footer-left">
        <p className="footer-links">
          <a
            onClick={() => {
              props.onChangePage("CompanyInformationPage");
            }}
            className="footer-link"
          >
            Impressum
          </a>

          <a
            onClick={() => {
              props.onChangePage("PrivacyPage");
            }}
            className="footer-link"
          >
            Datenschutzinformation
          </a>
        </p>
      </div>
      <p className="copyright">Grafiken &copy; Jacqueline Ullmann für Fraunhofer FIT</p>
      <p className="copyright"><a href="https://www.fit.fraunhofer.de/">Programmiert von Fraunhofer FIT</a></p>
    </footer>
  );
};

export default Footer;
