import ContentData from "../../Assets/Content/ContentData";

const GraphicTile = (props) => {
  let coordinates = props.coords;
  const { setContent, economyType } = props.AppStateService;
  return (
    <area
      coords={coordinates}
      shape="poly"
      onMouseEnter={() => {
        setContent(props.content);
      }}
      onMouseLeave={() => {
        let fixText =
        economyType === "linear"
            ? ContentData.LinearMain
            : ContentData.CycleMain;
        setContent(fixText);
      }}
    />
  );
};

export default GraphicTile;
