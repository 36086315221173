import kreis from "../../Assets/Images/kreis.png";
import linie from "../../Assets/Images/linie.png";
import fragezeichen from "../../Assets/Images/circle-question-solid.svg";
import "./MainPage.style.css";

import "antd/dist/antd.css";
import { Row, Col, Radio, Space } from "antd";

import CycleEconomyMap from "../../Components/CycleEconomyMap/CycleEconomyMap";
import LinearEconomyMap from "../../Components/LinearEconomyMap/LinearEconomyMap";
import Data from "../../Assets/Content/ContentData.js";
import imageMapResize from "image-map-resizer";
import { useState } from "react";

const MainPage = (props) => {
  const [resizeer, setResize] = useState("2px");
  const { content, setContent, economyType, setEconomyType } =
    props.AppStateService;

  let onChangeRadio = (e) => {
    let val = e.target.value;
    setEconomyType(val);
    console.log(economyType);
    if (val === "linear") {
      setContent(Data.LinearMain);
      setEconomyType(val);
    } else {
      setContent(Data.CycleMain);
      setEconomyType(val);
    }
  };

  window.addEventListener(
    "resize",
    (function () {
      try {
        imageMapResize();
        setTimeout(() => {
          setResize("1px");
        }, 500);
      } catch (e) {}
    })()
  );

  let getMap = (economyType) => {
    if (economyType === "linear") {
      return (
        <div className="map-container">
          <img
            src={linie}
            useMap="#image-map-full"
            style={{
              maxWidth: "100%",
              height: "auto",
            }}
          />
          <LinearEconomyMap
            resizeFactor={resizeer}
            AppStateService={{
              content,
              setContent,
              economyType,
              setEconomyType,
            }}
          />
        </div>
      );
    } else {
      return (
        <div className="map-container">
          <img
            src={kreis}
            useMap="#image-map-full"
            style={{
              maxWidth: "100%",
              height: "auto",
            }}
          />
          <CycleEconomyMap
            resizeFactor={resizeer}
            AppStateService={{
              content,
              setContent,
              economyType,
              setEconomyType,
            }}
          />
        </div>
      );
    }
  };

  return (
    <div className="Page">
      <div className="radio-button-container">
        <Radio.Group
          onChange={onChangeRadio}
          value={economyType}
          defaultValue={"cycle"}
        >
          <Space direction="vertical">
            <Radio value={"linear"} className="radio-button">
              Linearwirtschaft
            </Radio>
            <Radio value={"cycle"} className="radio-button">
              Kreislaufwirtschaft
            </Radio>
          </Space>
        </Radio.Group>
      </div>
      <div className="text-container">
        <div className="heading">{content?.heading}</div>
        {content?.text}
      </div>

      <img src={fragezeichen} className="question-logo"></img>
      <div className="hover-text">
        Wechseln sie zwischen der Ansicht „Kreislaufwirtschaft“ und „Linearwirtschaft“ mithilfe der Buttons links. 
        <br />
        Fahren sie mit der Maus über die Elemente der Grafik, um mehr zu erfahren.
      </div>
      {getMap(economyType)}
    </div>
  );
};

export default MainPage;
