import "./Navbar.style.css";

const Navbar = (props) => {
  return (
    <nav className="navbar-distributed">
      <a href="https://www.estandards-mittelstand.de/">
        <img
          src="mk4s.png"
          alt="Logo Mittelstand 4.0 Kompetenzzentrum eStandards"
          id="Mittelstand-Logo"
        />
      </a>
      <a
        onClick={() => {
          props.onChangePage("MainPage");
        }}
      >
        Startseite
      </a>
      <a
        onClick={() => {
          props.onChangePage("AboutUsPage");
        }}
      >
        Über uns
      </a>
      <a
        onClick={() => {
          props.onChangePage("ContactPage");
        }}
      >
        Kontakt
      </a>
      <p>
        Kreislaufwirtschafts-Guide
      </p>
    </nav>
  );
};

export default Navbar;
