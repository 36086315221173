const CompanyInformationPage = () => {
  return (
    <div className="Page">
      <h1>Impressung</h1>

      <p>
        Das
        <br /> <br /> Fraunhofer-Institut für Angewandte Informationstechnik FIT
        <br /> Schloss Birlinghoven
        <br /> 53757 Sankt Augustin
      </p>
      <p>ist eine rechtlich nicht selbstständige Einrichtung der</p>
      <p>
        Fraunhofer-Gesellschaft&nbsp;
        <br /> zur Förderung der angewandten Forschung e.V.
        <br /> Hansastraße 27 c<br /> 80686 München
        <br /> Internet:{" "}
        <a
          href="http://www.fraunhofer.de/"
          target="_blank"
        >
          www.fraunhofer.de
        </a>
        <br /> E-Mail: info(at)zv.fraunhofer.de
      </p>
      <h4>Verantwortlicher Redakteur</h4>
      <p>
        Alexander Schneider
        <br /> alexander.schneider@fit.fraunhofer.de
        <br /> Telefon: +49 2241 14-3789
      </p>

      <p>
        Umsatzsteuer-Identifikationsnummer gemäß § 27 a<br />{" "}
        Umsatzsteuergesetz: DE 129515865
      </p>
      <p>
        Registergericht
        <br /> Amtsgericht München
        <br /> Eingetragener Verein
        <br /> Register-Nr. VR 4461
        <br />{" "}
      </p>
      <h4>Vorstand</h4>
      <p>
        <b>Prof. Dr.-Ing. Reimund Neugebauer</b> | Präsident |
        Unternehmensstrategie, Forschung und Kommunikation
        <br />
        <b>Prof. Dr. Alexander Kurz</b> | Innovation, Transfer und Verwertung |
        Personal, Unternehmenskultur und Recht (kommissarisch)
        <br />
        <b>Dipl.-Kfm. Andreas Meuer</b> | Finanzen und Controlling |
        Forschungsinfrastrukturen und Digitalisierung (kommissarisch)
        <br />
      </p>
      <h4>Nutzungsrechte</h4>
      <p>
        © Fraunhofer-Gesellschaft e.V., München
        <br /> <br /> Alle Rechte vorbehalten.
        <br /> Die Urheberrechte dieser Webseite liegen vollständig bei der
        Fraunhofer-Gesellschaft.
        <br />{" "}
      </p>
      <p>
        Ein Download oder Ausdruck dieser Veröffentlichungen ist ausschließlich
        für den persönlichen Gebrauch gestattet. Alle darüber hinaus gehenden
        Verwendungen, insbesondere die kommerzielle Nutzung und Verbreitung,
        sind grundsätzlich nicht gestattet und bedürfen der schriftlichen
        Genehmigung.
        <br />{" "}
      </p>
      <p>
        Ein Download oder Ausdruck ist darüber hinaus lediglich zum Zweck der
        Berichterstattung über die Fraunhofer-Gesellschaft und ihrer Institute
        nach Maßgabe untenstehender Nutzungsbedingungen gestattet:
      </p>
      <p>
        Grafische Veränderungen an Bildmotiven - außer zum Freistellen des
        Hauptmotivs - sind nicht gestattet. Es ist stets die Quellenangabe und
        Übersendung von zwei kostenlosen Belegexemplaren an die oben genannte
        Adresse erforderlich. Die Verwendung ist honorarfrei.
      </p>
      <h4>Haftungshinweis</h4>
      <p>
        Wir übernehmen keine Haftung für die Inhalte externer Links. Für den
        Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber
        verantwortlich.
      </p>
      <p>
        Wir sind bemüht, das Webangebot stets aktuell und inhaltlich richtig
        sowie vollständig anzubieten. Dennoch ist das Auftreten von Fehlern
        nicht völlig auszuschließen. Das Fraunhofer-Institut bzw. die
        Fraunhofer-Gesellschaft übernimmt keine Haftung für die Aktualität, die
        inhaltliche Richtigkeit sowie für die Vollständigkeit der in ihrem
        Webangebot eingestellten Informationen. Dies bezieht sich auf eventuelle
        Schäden materieller oder ideeller Art Dritter, die durch die Nutzung
        dieses Webangebotes verursacht wurden.
      </p>
      <p>
        Geschützte Marken und Namen, Bilder und Texte werden auf unseren Seiten
        in der Regel nicht als solche kenntlich gemacht. Das Fehlen einer
        solchen Kennzeichnung bedeutet jedoch nicht, dass es sich um einen
        freien Namen, ein freies Bild oder einen freien Text im Sinne des
        Markenzeichenrechts handelt.
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </div>
  );
};

export default CompanyInformationPage;
