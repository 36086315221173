const AboutUsPage = () => {
  return (
    <div className="Page">
      <h1>
        Ihr Partner für nachhaltige Digitalisierung mit Standards im Mittelstand
      </h1>
      Das Mittelstand 4.0-Kompetenzzentrum eStandards unterstützt Sie als
      mittelständisches Unternehmen bei der Entwicklung und Umsetzung von
      nachhaltigen und digitalen Geschäftsideen mit Hilfe von Standards für den
      elektronischen Geschäftsverkehr, kurz eStandards. eStandards kommen bei
      der Digitalisierung Ihrer Geschäftsprozesse zum Einsatz. Sie sind die
      „Dolmetscher“ an den Schnittstellen zwischen Akteuren sowie Systemen und
      sorgen für einen rei­bungslosen Datenaustausch im Unternehmen und zwischen
      Unternehmen. Wir zeigen Ihnen, wie Ihr digitales Geschäftsmodell mit
      eStandards einfach und unabhängig gelingt. 
      <p style={{lineHeight: "5px"}} />
      Mit spannenden Best Practices
      wollen wir Sie inspirieren, Ihre eigene nachhaltige, digitale und
      standardisierte Erfolgsgeschichte zu schreiben. Erproben Sie den Nutzen
      von Standards in den Offenen Werkstätten des Mittelstand
      4.0-Kompetenzzentrum eStandards. Bauen Sie Ihr digitales Know-how auf oder
      erweitern Sie dieses auf Exkursionen, in Schulungen, mit unseren
      Leitfäden, Checklisten und Tools. Starten Sie einfach und unabhängig Ihr
      digitales Geschäftsmodell – mit eStandards. Wir begleiten Sie gerne.
      <p style={{lineHeight: "5px"}} />
      Unsere Angebote (die Sie übrigens in komprimierter Form in unserer
      <a href="https://www.estandards-mittelstand.de/fileadmin/user_upload/Materialien/Sonstiges/Kompetenzzentrum-eStandards-Imagebroschuere_201026-web.pdf"> Imagebroschüre</a> finden) sind für Sie als kleines und mittleres Unternehmen
      (KMU) selbstverständlich kostenlos und anbieterneutral. Hierauf können Sie
      sich verlassen, da wir Teil der Förderinitiative „Mittelstand 4.0" sind.
      Diese wird im Rahmen des Förderschwerpunkts „Mittelstand-Digital –
      Strategien zur digitalen Transformation der Unternehmensprozesse“ vom
      Bundesministerium für Wirtschaft und Klimaschutz (BMWK) gefördert.
      <p style={{lineHeight: "5px"}} />
    </div>
  );
};

export default AboutUsPage;
